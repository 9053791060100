<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Sellers">
      <b-row>
        <!-- Sell Type -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Product Type"
            label-cols-sm="5"
            label-align-sm="center"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="sellType"
              class="border mr-0"
              size="sm"
              :text="sellTypeName"
              variant="flat-primary"
            >
              <!-- <div style="max-height: 350px;"> -->
              <b-dropdown-item @click="changeSellType('All', 'all')">
                All
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeSellType(item.name, item.slug)"
                v-for="(item, index) in orderSellType"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
              <!-- </div> -->
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- Classification -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Sales Channel"
            label-cols-sm="5"
            label-align-sm="center"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="classification"
              class="border mr-0"
              size="sm"
              :text="classificationName"
              variant="flat-primary"
            >
              <!-- <div style="max-height: 350px;"> -->
              <b-dropdown-item @click="changeClassification('All', 'all')">
                All
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeClassification(item.name, item.slug)"
                v-for="(item, index) in orderClassification"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
              <!-- </div> -->
            </b-dropdown>
          </b-form-group>
        </b-col>

         <!-- status -->
         <b-col md="2" class="my-1">
          <b-form-group
            label="Status Type"
            label-cols-sm="5"
            label-align-sm="center"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="statusType"
              class="border mr-0"
              size="sm"
              :text="statusTypeName"
              variant="flat-primary"
            >
              <!-- <div style="max-height: 350px;"> -->
              <b-dropdown-item @click="changestatusType('All', 'all')">
                All
              </b-dropdown-item>
              <b-dropdown-item
                @click="changestatusType(item.name, item.slug)"
                v-for="(item, index) in orderstatusType"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
              <!-- </div> -->
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="removeExitem"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>
        <b-col md="2" class="my-1">
          <!-- add new vendor button -->
          <!-- <div class="d-flex align-items-center justify-content-end">
            <b-button
              style="height:2.142rem; line-height:0.5"
              v-if="authPermissions.includes('create')"
              variant="primary"
              :to="{ path: '/Vendor/Add' }"
            >
              <span class="text-nowrap">Add Vendor</span>
            </b-button>
          </div> -->
        </b-col>
        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="tagTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
          
            <template #cell(storename)="data">
             
              <span v-if="data.item.verified_at != null">
                <img
                  :src="require('@/assets/images/icons/verified-icon-small.png')"
                 />
                </span>
                  {{ data.value }}


            </template>
            <template #cell(status)="data">
              <b-badge
                v-if="data.item.status_slug === 'active'"
                variant="primary"
              >
                {{ data.value }}
              </b-badge>
              <b-badge
                v-if="data.item.status_slug === 'pending'"
                variant="success"
              >
                {{ data.value }}
              </b-badge>
              <b-badge
                v-if="data.item.status_slug === 'disabled'"
                variant="warning"
              >
                {{ data.value }}
              </b-badge>
              <b-badge
                v-if="data.item.status_slug === 'rejected'"
                variant="warning"
              >
                {{ data.value }}
              </b-badge>
            </template>
            
            <template #cell(selling_type)="data">
              <b-badge pill 
                class="mx-1"
                v-if="data.item.is_sell_new"
                variant="success"
              >
                New
              </b-badge>
              <b-badge pill 
                v-if="data.item.is_sell_used"
                variant="warning"
              >
                Used
              </b-badge>
              
            </template>

            <template #cell(classification)="data">
              <b-badge
                class="mx-1"
                v-if="data.item.portal"
                variant="success"
              >
                Portal
              </b-badge>
              <b-badge
                v-if="data.item.in_store"
                variant="warning"
              >
                In Store
              </b-badge>
              
            </template>
            <template #cell(actions)="items">
              <div>
                <b-button
                  v-if="
                    items.item.type == 'company' ||
                    items.item.type == 'personal'
                  "
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{
                    path: '/Vendor/Show/' + items.item.id,
                    params: { statusName: items.item.name },
                  }"
                >
                  <feather-icon
                    style="width: 18px; height: 18px"
                    icon="EyeIcon"
                  />
                </b-button>
                <b-button
                  v-else
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{
                    path: '/Vendor/Show/' + items.item.id,
                    params: { statusName: items.item.name },
                  }"
                >
                  <feather-icon
                    style="width: 18px; height: 18px"
                    icon="EyeIcon"
                  />
                </b-button>
                <!-- export active variants-->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  type="submit"
                  @click="getItem(items.item)" 
                  v-b-modal.modal-share-social
                >
                  <feather-icon
                    style="width: 18px; height: 18px"
                    icon="DownloadIcon"
                  />
                </b-button>

                <!-- delete button -->
                <b-button
                  v-if="authPermissions.includes('delete')"
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  type="submit"
                  @click="deleteData(items.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>

    
        <!-- model  update package -->
        <b-modal size="md" id="modal-share-social" cancel-variant="outline-secondary" style="min-height: 300px !important;"
                    ok-title="Close"  centered title="share Excel File" ok-only>
                    <validation-observer ref="updatedSubscriptionForm">
                        <b-form @submit.prevent>
                        <b-row>

                            <b-col md="12" class="mt-2 d-flex justify-content-center">
                                <b-button
                                    style="padding: 7px; margin: 6px; width: 160px; height: 35px"
                                    class="btn-icon"
                                    size="sm"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="warning"
                                    type="submit"
                                    @click="exportActiveVariants(sellectedSeller,'download')"
                                    v-b-modal.modal-prevent-closing
                                >
                                Dwonload <feather-icon
                                    style="width: 30px;"
                                    icon="DownloadIcon"
                                    />
                                </b-button>
                            </b-col>
        
                        </b-row>

                        <b-row>

                        
                          <!--Download-pdf -->                          
                          <b-col md="12" class="mt-2 d-flex justify-content-center" >
                                <b-button
                                    style="padding: 7px; margin: 6px; width: 160px; height: 35px"
                                    class="btn-icon"
                                    size="sm"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="warning"
                                    type="submit"
                                    @click="exportActiveVariants(sellectedSeller,'download-pdf')"
                                    v-b-modal.modal-prevent-closing>
                                    Download pdf
                                </b-button>
                              </b-col>

                              <b-col cols="12" v-if="getPDFLinkVisiable == true">
                                <p class="text-primary mb-0">PDF Link</p>
                                <div class="d-flex justify-content-start">
                                <p
                                    id="myInput"
                                    v-if="SharePDFFileUrl"
                                    class="text-primary p-1 mb-0 border border-primary rounded"
                                >
                                <a :href="SharePDFFileUrl" target="_blank">
                                    {{ SharePDFFileUrl.slice(0, 75) + '...' }}
                                </a>
                                </p>
                                <b-button @click="copyPDF" variant="none">
                                    <feather-icon size="20" icon="CopyIcon" /> Copy
                                </b-button>
                                </div>
                            </b-col>

                          <!-- get Link -->
                          <b-col md="12" class="mt-2 d-flex justify-content-center">
                                <b-button
                                    style="padding: 7px; margin: 6px; width: 160px; height: 35px"
                                    class="btn-icon"
                                    size="sm"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    type="submit"
                                    @click="exportActiveVariants(sellectedSeller,'getLink')"
                                    v-b-modal.modal-prevent-closing
                                >
                                    get File Link
                                </b-button>
                            </b-col>


                            <b-col cols="12" v-if="getLinkVisiable == true">
                                <p class="text-primary mb-0">File Link</p>
                                <div class="d-flex justify-content-start">
                                <p
                                    id="myInput"
                                    v-if="ShareFileUrl"
                                    class="text-primary p-1 mb-0 border border-primary rounded"
                                >
                                    {{ ShareFileUrl.slice(0, 75) + '...' }}
                                </p>
                                <b-button @click="copy" variant="none">
                                    <feather-icon size="20" icon="CopyIcon" /> Copy
                                </b-button>
                                </div>

                                
                            </b-col>


                            
                            <b-col md="12" class="mt-2 d-flex justify-content-center">
                                <b-button
                                    style="padding: 7px; margin: 6px; width: 160px; height: 35px"
                                    class="btn-icon"
                                    size="sm"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    type="submit"
                                    @click="exportActiveVariants(sellectedSeller,'ShareFileSochial')"
                                    v-b-modal.modal-prevent-closing
                                >
                                    
                                    Share
                                </b-button>
                            </b-col>

                                <b-col cols="12" class="text-center p-1" v-if="shareVisiable == true">
                                <p class="h3 text-secondary mb-1 font-weight-bold text-center">
                                Share link
                                </p>
                                <b-row>
                                <b-col
                                    class="mb-2"
                                    v-for="network in networks"
                                    :key="network.network"
                                >
                                    <ShareNetwork
                                    :network="network.network"
                                    :style="{ backgroundColor: network.color }"
                                    :url="ShareFileUrl"
                                    :title="'Check out this file From MobileMasr:'"
                                    :description="'Seller Excel file'"
                                    class="h-100 w-100"
                                    >
                                    <span class="text-white p-2">{{ network.name }}</span>
                                    </ShareNetwork>
                                </b-col>
                                </b-row>
                            </b-col>



                          

                        </b-row>


                        
                            
                        </b-form>
                    </validation-observer>
                    <!-- error handelr -->
                    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
                        variant="danger">
                        <h4 class="alert-heading">Alert</h4>
                        <div class="alert-body">
                        <ul v-for="(values, index) in errors_back" :key="index">
                            <li v-for="(value, indexVal) in values" :key="indexVal">
                            {{ value }}
                            </li>
                        </ul>
                        </div>
                    </b-alert>
                    </b-modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      classificationName: 'All',
      classification: 'all',
      statusTypeName: 'all',
      statusType: 'all',
      orderClassification: [
        {
          name: 'Portal',
          slug: 'portal',
        },
        {
          name: 'In Store',
          slug: 'in_store',
        },
      ],
      orderstatusType: [
      {
          name: 'Active',
          slug: 'active',
        },
        {
          name: 'Pending',
          slug: 'pending',
        },
        {
          name: 'Disabled',
          slug: 'disabled',
        },
        {
          name: 'Rejected',
          slug: 'rejected',
        },
        
      ],
      sellTypeName: 'All',
      sellType: 'all',
      orderSellType: [
        {
          name: 'New',
          slug: 'new',
        },
        {
          name: 'Used',
          slug: 'used',
        },
      ],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'storename',
          label: 'Store Name',
          class: 'th-spaces-update',
        },
        {
          key: "email",
          label: "Email",
          class: 'th-spaces-update',
        },

        {
          key: 'code',
          label: 'Code',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'status',
          label: 'Status',
          class: 'th-spaces-update',
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'selling_type',
          label: 'Selling Products',
          class: 'th-spaces-update',
        },
        {
          key: 'classification',
          label: 'Sales Channel',
          class: 'th-spaces-update',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      exportItems: [],

      networks: [
        {
          network: 'whatsapp',
          name: 'Whatsapp',
          color: '#25d366',
        },
       
        {
          network: 'messenger',
          name: 'Messenger',

          color: '#0084ff',
        },

        {
          network: 'email',
          name: 'Email',

          color: '#333333',
        },

       

        {
          network: 'telegram',
          name: 'Telegram',

          color: '#0088cc',
        },
      ],
        sellectedSeller: {},
        ShareFileUrl: null,
        SharePDFFileUrl: null,
        getLinkVisiable: false,
        getPDFLinkVisiable: false,
        shareVisiable: false,
        Exportresult: {}
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request tags index
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
    classification: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sellType: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    statusType: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
  },
  methods: {
    // fetchData of vendors
    fetchData() {
      this.showDismissibleAlert = false
      // request vendors index API
      var url = null
      if (this.filter == '') {
        url =
          'vendor-pagi/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.statusType+
          '/' +
          this.sellType+
          '/' +
          this.classification +
        '?page=' + this.currentPage
      } else {
        url =
          'vendor-pagi/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.statusType+
          '/' +
          this.sellType +
          '/' +
          this.classification +
        '/' + this.filter + '?page=' + this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          this.items = result.data.data.data

          this.exportItems = this.items

          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    changeClassification(name, slug) {
      this.classification = slug
      this.classificationName = name
    },
    changestatusType(name, slug) {
      this.statusType = slug
      this.statusTypeName = name
    },
    changeSellType(name, slug) {
      this.sellType = slug
      this.sellTypeName = name
    },
    

    // Export Active Variants For Vendor
    exportActiveVariants(item,type) {


      const current = new Date();

          // Format the date to 'Y-m-d' format
          const dateFormatter = new Intl.DateTimeFormat('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
          });

      const [{ value: year }, , { value: month }, , { value: day }] = dateFormatter.formatToParts(current);

      const formattedDate = `${year}-${month}-${day}`;
          
      if(this.ShareFileUrl == null  || type == 'download'){

      axios
      .get('variants/vendors/' + item.id + '/active/export/' + type, {
          responseType: type == 'download' ?  'blob' : '',
          
      })
      .then((result) => {
          this.Exportresult = result;


          if(type == 'download'){
            setTimeout(() => {

              const url = window.URL.createObjectURL(new Blob([this.Exportresult.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
              'download',
              'Products_'+ item.code + '_' + formattedDate + '.xlsx'
              )
              document.body.appendChild(link)
              link.click()



              this.$swal({
              position: 'center',
              icon: 'success',
              title: 'downloaded success',
              showConfirmButton: false,
              timer: 1500,
              })
            }, 1000);
          }else if (type == 'download-pdf')
          {
              this.SharePDFFileUrl = this.Exportresult.data.export_url;
              this.getPDFLinkVisiable = true;
            }
            else if (type == 'download-pdf'){
              this.SharePDFFileUrl = this.Exportresult.data.export_url;
              this.getPDFLinkVisiable = true;
            }
            else if(type == 'whatsapp')
          {
              const whatsShareUrl = `https://api.whatsapp.com/send?text=Check out this file From mobileMasr: ${this.Exportresult.data.export_url}`;
              // Open the Facebook sharing dialog in a new tab/window
              window.open(whatsShareUrl, '_blank');

          }
          else if(type == 'getLink')
          {
              this.ShareFileUrl = this.Exportresult.data.export_url;
              this.getLinkVisiable = true;

          }
          else if( type == 'ShareFileSochial')
          {
          this.ShareFileUrl = this.Exportresult.data.export_url;
          this.shareVisiable = true;
          }



      })
      .catch((err) => {
          //console.log(err)
          this.$swal({
          position: 'center',
          icon: 'error',
          title: 'Error!',
          showConfirmButton: false,
          timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
              this.errors_back.push({
              error: err.response.data.data,
              })
          } else {
              this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
          } else {
          this.errors_back = []
          this.errors_back.push({
              error: 'internal server error',
          })
          this.showDismissibleAlert = true
          }
      });

      }else{


      // const path = 'https://mobilemasr-assets-bucket.s3.eu-central-1.amazonaws.com/public/exports/Products_'+ item.code + '_' + formattedDate + '.xlsx';

          if(type == 'download'){
            setTimeout(() => {

              const url = window.URL.createObjectURL(new Blob([this.Exportresult.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
              'download',
              'Products_'+ item.code + '_' + formattedDate + '.xlsx'
              )
              document.body.appendChild(link)
              link.click()



              this.$swal({
              position: 'center',
              icon: 'success',
              title: 'downloaded success',
              showConfirmButton: false,
              timer: 1500,
              })
            }, 1000);
          }else if(type == 'whatsapp')
          {
              const whatsShareUrl = `https://api.whatsapp.com/send?text=Check out this file From mobileMasr: ${this.Exportresult.data.export_url}`;
              // Open the Facebook sharing dialog in a new tab/window
              window.open(whatsShareUrl, '_blank');

          }
          else if(type == 'getLink')
          {
              this.ShareFileUrl = this.Exportresult.data.export_url;
              this.getLinkVisiable = true;

          }
          else if( type == 'ShareFileSochial')
          {
          this.ShareFileUrl = this.Exportresult.data.export_url;
          this.shareVisiable = true;
          }
        }
      },

      getItem(item){
            this.sellectedSeller = item;
            this.ShareFileUrl = null;
            this.SharePDFFileUrl = null;
            this.getLinkVisiable = false;
            this.getPDFLinkVisiable = false;
            this.shareVisiable = false;
        },
        copy() {
        // let text = document.getElementById('myText').innerHTML;
        navigator.clipboard.writeText(this.ShareFileUrl)
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'Copied',
          showConfirmButton: false,
          timer: 1500,
        })
      },
      copyPDF() {
        // let text = document.getElementById('myText').innerHTML;
        navigator.clipboard.writeText(this.SharePDFFileUrl)
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'Copied',
          showConfirmButton: false,
          timer: 1500,
        })
    },
    // deleted function to delete vendor
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete seller.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('vendors/' + id + '/archive')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].picture
      }
    },
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].storename,
          this.items[index].type,
          this.items[index].status,
          this.items[index].code,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [
          [
            'ID',
            'Name',
            'Store',
            'Type',
            'Status',
            'Code',
            'CREATED_AT',
            'UPDATED_AT',
          ],
        ],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
  },
}
</script>
  
<style lang="css" >
.b-table-sticky-header{
  max-height: 92vh;
}
.th-spaces-update {
white-space: nowrap;
text-align: center;
direction: ltr;
}
.month-picker__container,
.month-picker--default {
background-color: #fff;
}
[dir] .month-picker-input[data-v-201d773d] {
height: 38px !important;
width: 100% !important;
}
.month-picker {
font-weight: 200;
box-sizing: border-box;
flex: 1;
width: auto;
display: flex;
flex-direction: row;
flex-wrap: wrap;
font-family: sans-serif;
overflow: hidden;
justify-content: space-evenly;
align-content: space-around;
}
.dountRevenu {
height: 75% !important;
}
.month-picker-input-container[data-v-201d773d] {
width: 100% !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
